import React from 'react';
import usePercentageScrolled from 'hooks/queries/usePercentageScrolled';
import styled from 'styled-components';
import { defaultFunc, GetAttrFunction } from './getAttrFuncs';

export * from './getAttrFuncs';

export interface Props {
  children: React.ReactElement,
  wrapperElement?: React.ElementType,
  multiplier?: number,
  reverse?: boolean,
  getAttrs?: GetAttrFunction,
  targetRef?: React.RefObject<HTMLElement>,
}

const Parallaxer:React.FC<Props> = ({
  children,
  wrapperElement = 'div',
  multiplier = 0.1,
  reverse = false,
  getAttrs = defaultFunc,
  targetRef: targetRefProp,
}) => {
  const [targetRef, percentageScrolled] = usePercentageScrolled<HTMLElement>(targetRefProp);
  const conditionalDefaultRef = targetRefProp ? undefined : targetRef;

  return (
    <ParallaxerOuter
      as={wrapperElement}
      ref={conditionalDefaultRef}
      percentageScrolled={percentageScrolled}
      multiplier={multiplier}
      getAttrs={getAttrs}
      reverse={reverse}
    >
      <Inner>
        {children}
      </Inner>
    </ParallaxerOuter>
  );
};
export default Parallaxer;


interface OuterProps {
  getAttrs: GetAttrFunction,
  percentageScrolled: number,
  multiplier: number,
  reverse: boolean,
}
const ParallaxerOuter = styled.div.attrs<OuterProps>(({ getAttrs, ...props }) => getAttrs(props))<OuterProps>`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
`;
const Inner = styled.div``;
