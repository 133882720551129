export type GetAttrFunction = (props: {
  percentageScrolled: number,
  multiplier: number,
  reverse: boolean,
}) => Record<string, any>;

export const defaultFunc: GetAttrFunction = ({
  percentageScrolled,
  multiplier,
  reverse,
}) => {
  const value = percentageScrolled * multiplier * (reverse ? -1 : 1);
  return {
    style: {
      transform: `translateY(${value}%)`,
    },
  };
};

export const scale: GetAttrFunction = ({
  percentageScrolled,
  multiplier,
  reverse,
}) => {
  const scaleVal = 1 - (percentageScrolled / 100) * (multiplier * 10) * (reverse ? -1 : 1);
  return {
    style: {
      transform: `scale(${scaleVal})`,
    },
  };
};

export const scaleMove: GetAttrFunction = ({
  percentageScrolled,
  multiplier,
  reverse,
}) => {
  const scaleVal = 1 - (percentageScrolled / 100) * (multiplier * 3) * (reverse ? 1 : -1);
  const translateVal = percentageScrolled * multiplier * (reverse ? -1 : 1);
  return {
    style: {
      transform: `scale(${scaleVal}) translateY(${translateVal}%)`,
    },
  };
};
