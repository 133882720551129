import React from 'react';
import Parallaxer, { GetAttrFunction } from 'components/Parallaxer';
import { easeCubicIn as d3Easing } from 'd3-ease';
import TypographyBackground from './TypographyBackground';

export interface Props {}

const ParallaxingTypographyBackground:React.FC<Props> = () => {
  const parallaxerGetAttrs: GetAttrFunction = ({
    multiplier,
    percentageScrolled: percentageScrolledRaw,
  }) => {
    const percentageScrolled = d3Easing(percentageScrolledRaw / 100) * 100;
    const scaleVal = 1 - (percentageScrolled / 100) * (multiplier * 15) * -1;
    // const translateVal = percentageScrolled * multiplier;
    const rotateVal = percentageScrolled * multiplier;
    const opacityVal = 1 - percentageScrolled / 90;
    const transforms = [
      `scale(${scaleVal})`,
      // `translateY(${translateVal})`,
      `rotate(${rotateVal}deg)`,
    ].join(' ');
    return {
      style: {
        transform: transforms,
        opacity: opacityVal,
      },
    };
  };

  return (
    <Parallaxer getAttrs={parallaxerGetAttrs}>
      <TypographyBackground />
    </Parallaxer>
  );
};
export default ParallaxingTypographyBackground;
