import React from 'react';
import styled, { keyframes } from 'styled-components';

export interface Props {
  children: string,
  wrapperElement?: React.ElementType,
  animationDelay?: number,
  animationDuration?: number,
}

const AnimateLetters:React.FC<Props> = ({
  children,
  wrapperElement = 'span',
  animationDelay = 0,
  animationDuration = 50,
}) => {
  const letters = children.split('');
  return (
    <>
      {letters.map((letter, i) => (
        <Wrap
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          index={i}
          as={wrapperElement}
          animationDelay={animationDelay}
          animationDuration={animationDuration}
        >
          {letter}
        </Wrap>
      ))}
    </>
  );
};
export default AnimateLetters;

interface WrapProps {
  animationDelay: number,
  animationDuration: number,
  index: number,
}

const Wrap = styled.div<WrapProps>`
  animation: ${keyframes`
    from { opacity: 0; }
    to { opacity: 1; }
  `} 0.5s both;
  animation-delay: ${(p) => (p.index * p.animationDuration) + p.animationDelay}ms;
`;
