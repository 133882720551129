import React from 'react';
import styled from 'styled-components';
import Svg from 'components/Svg';
import { nanoid } from 'nanoid';
import AnimateLetters from 'src/components/AnimateLetters';

export interface Props {
  repetitions?: number,
  baseSize?: number,
}

const TypographyBackground:React.FC<Props> = ({
  repetitions = 3,
  baseSize = 130,
}) => {
  const textIds = Array.from({ length: repetitions }, () => nanoid());
  const lineHeightMultiplier = 1.3;
  const fontSize = baseSize / 9;
  const strokeWidth = baseSize / 500;
  const lines = [
    'A creative data-',
    'led SEO agency',
  ];

  const viewBoxWidth = (
    repetitions
    * (lines.length * fontSize)
    * lineHeightMultiplier
  ) * 1.4;

  return (
    <TypographyBackgroundOuter>
      <StyledSvg viewBox={`0 0 ${baseSize} ${viewBoxWidth}`}>
        {textIds.map((id, i) => (
          <Text
            key={id}
            x={0}
            y={i * (fontSize * 2) * lineHeightMultiplier}
            dominantBaseline="hanging"
            fontSize={fontSize}
            strokeWidth={strokeWidth}
            transform={`translate(${(baseSize * -0.1) * i}, ${baseSize * 0.5}) rotate(${-30})`}
          >
            {lines.map((line, ii) => (
              <Tspan
                key={line}
                x={0}
                dy={`${ii}em`}
              >
                <AnimateLetters
                  wrapperElement="tspan"
                  animationDuration={20}
                  animationDelay={(
                    (lines[ii - 1]?.length || 0) * 10
                    + lines.join('').length * i * 10
                    + 800
                  )}
                >
                  {line}
                </AnimateLetters>
              </Tspan>
            ))}
          </Text>
        ))}
      </StyledSvg>
    </TypographyBackgroundOuter>
  );
};
export default React.memo(TypographyBackground);

const TypographyBackgroundOuter = styled.div`
  position: absolute;
  width: 100%;
  height: 180%;
  top: -40%;
  left: 0;
  display: flex;
  align-items: center;
  opacity: 0.3;
`;
const StyledSvg = styled(Svg)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;
const Text = styled.text`
  fill: transparent;
  stroke: ${(p) => p.theme.palette.secondary};
  font-weight: bold;
  font-family: ${(p) => p.theme.type.font2};
`;
const Tspan = styled.tspan``;
