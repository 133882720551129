import React from 'react';
import { clamp } from 'src/helpers';

const usePercentageScrolled = <RefType extends HTMLElement>(
  refOverride?: React.RefObject<RefType>,
): [React.RefObject<RefType>, number] => {
  const targetRefDefault = React.useRef<RefType>(null);
  const targetRef = refOverride || targetRefDefault;
  const [isIntersecting, setIsIntersecting] = React.useState(false);
  const [scrollPercentage, setScrollPercentage] = React.useState(0);
  const raf = React.useRef(0);

  const handleScroll = React.useCallback(() => { // add rate limiting?
    if (isIntersecting && targetRef.current) {
      const targetBbox = targetRef.current.getBoundingClientRect();
      const top = targetBbox.y;
      const percentageScrolled = ((top * -1) / targetBbox.height) * 100;
      const clamped = clamp(percentageScrolled, 0, 100);
      setScrollPercentage(clamped);
    }
  }, [isIntersecting, targetRef]);

  const handleIntersection = React.useCallback<IntersectionObserverCallback>((entries) => {
    const [firstEntry] = entries;
    raf.current = window.requestAnimationFrame(() => {
      setIsIntersecting(firstEntry.isIntersecting);
    });
  }, []);

  React.useEffect(() => {
    if (targetRef.current) {
      const observer = new IntersectionObserver(handleIntersection, {
        rootMargin: '0px',
        threshold: 0,
      });
      observer.observe(targetRef.current);
      window.addEventListener('scroll', handleScroll);
      return function unmount() {
        observer.disconnect();
        window.removeEventListener('scroll', handleScroll);
        window.cancelAnimationFrame(raf.current);
      };
    }
    return undefined;
  }, [handleScroll, handleIntersection, targetRef]);

  if (targetRef.current === null) return [targetRef, 0];

  return [
    targetRef,
    scrollPercentage,
  ];
};

export default usePercentageScrolled;
